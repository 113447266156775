/* src/styles/tailwind.css */

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  ul, ol {
    list-style: revert;
  }
}

.cgmn-btn-green{
  background-color: #00a65a;
  color: white
}

.cgmn-btn-green:hover{
  background-color: #029754;
}